import config from './config';
import axios from 'axios';
import { showError } from './utils';

export function getCenterDoctorPractices(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(
      `${config.baseUrl}/network/practice/digitisations-maori-center-doctor-search`,
      {
        headers,
        params: {
          keyword: searchQuery,
          r: 1,
        },
      }
    )
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.results;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function getCenters(authToken, searchQuery) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.baseUrl}/network/practice/center-doc`, {
      headers,
      params: {
        q: searchQuery,
        r: 1,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
