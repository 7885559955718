import axios from 'axios';
import config from '../config';
import { store } from '../store/';

const version = 6;
export async function getReimbursementdata(
  params,
  sortBy,
  sortOrder,
  myCasesFilter
) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  const urlQuery = new URLSearchParams(params).toString();
  return await axios
    .get(
      `${config.baseUrl}/claims-support/reimbursements?sortOrder[]=${sortOrder}&sortBy[]=${sortBy}&v=${version}&${urlQuery}`,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'recordsFound') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export async function fetchRequestHistory(requestId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/request/${requestId}/history`;
    const getHistory = await axios.get(url, { headers });

    if (getHistory) {
      return getHistory.data.data;
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getRejectionReason() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/get/reimbursement-rejection-reason`;

    const getRejectionList = await axios.get(url, { headers });

    if (getRejectionList) {
      return getRejectionList.data.result;
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getClaim(reimbursementId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}?v=${version}`;

    const getClaim = await axios.get(url, { headers });

    if (getClaim) {
      return getClaim.data.result;
    }
  } catch (err) {
    const errorMsg = err.response.data;
    throw errorMsg;
  }
}

export async function placeCall(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/voice-call/initiate`;
    const placeCall = await axios.post(url, body, { headers });
    const responseMsg = 'Call Initiated';
    if (placeCall) {
      return responseMsg;
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function copyClaim(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/copy-claim`;
    const copyClaim = await axios.post(url, body, { headers });
    if (copyClaim) {
      if (copyClaim.data.message === 'success') {
        return copyClaim.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function deleteClaim(info) {
  const { claimId } = info;

  const body = {
    reason: info.reason,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${claimId}`;
    const deleteClaim = await axios.delete(url, { headers, data: body });
    if (deleteClaim) {
      if (deleteClaim.data.message === 'success') {
        return deleteClaim.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function uploadInvoice(data) {
  const { reimbursementId, onUploadProgress } = data;
  const fileData = new FormData();
  fileData.append('invoiceFile', data.file);

  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  let updatedOptions;
  if (onUploadProgress) {
    updatedOptions = Object.assign({}, onUploadProgress);
    updatedOptions.headers = {
      ...headers,
      ...onUploadProgress.headers,
    };
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/add-additional-invoices`;
    const uploadInvoice = await axios.put(url, fileData, { ...updatedOptions });
    if (uploadInvoice) {
      if (uploadInvoice.data.message === 'success') {
        return uploadInvoice.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function uploadPrescription(data) {
  const { reimbursementId, onUploadProgress } = data;
  const fileData = new FormData();
  fileData.append('prescriptionFile', data.file);

  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  let updatedOptions;
  if (onUploadProgress) {
    updatedOptions = Object.assign({}, onUploadProgress);
    updatedOptions.headers = {
      ...headers,
      ...onUploadProgress.headers,
    };
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/add-additional-prescriptions`;
    const uploadPrescription = await axios.put(url, fileData, {
      ...updatedOptions,
    });
    if (uploadPrescription) {
      if (uploadPrescription.data.message === 'success') {
        return uploadPrescription.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function uploadMerFile(data) {
  const { reimbursementId, onUploadProgress } = data;
  const fileData = new FormData();
  fileData.append('merFile', data.file);
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  let updatedOptions;
  if (onUploadProgress) {
    updatedOptions = Object.assign({}, onUploadProgress);
    updatedOptions.headers = {
      ...headers,
      ...onUploadProgress.headers,
    };
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/add-additional-mers`;
    const uploadPrescription = await axios.put(url, fileData, {
      ...updatedOptions,
    });
    if (uploadPrescription) {
      if (uploadPrescription.data.message === 'success') {
        return uploadPrescription.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function deleteInvoice(data) {
  const { reimbursementId, invoiceUrl } = data;
  const body = {
    invoiceUrl,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/invoice`;
    const options = {
      url,
      method: 'DELETE',
      headers,
      data: body,
    };
    const deleteInvoice = await axios(options);

    if (deleteInvoice) {
      if (deleteInvoice.data.message === 'success') {
        return deleteInvoice.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function deletePrescription(data) {
  const { reimbursementId, prescriptionUrl } = data;
  const body = {
    prescriptionUrl,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/prescription`;
    const options = {
      url,
      method: 'DELETE',
      headers,
      data: body,
    };
    const deletePrescription = await axios(options);

    if (deletePrescription) {
      if (deletePrescription.data.message === 'success') {
        return deletePrescription.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function deleteInvoiceItem(data) {
  const { reimbursementId, itemId } = data;

  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/invoice/${itemId}`;
    const options = {
      url,
      method: 'DELETE',
      headers,
      data,
    };
    const deleteInvoice = await axios(options);

    if (deleteInvoice) {
      if (deleteInvoice.data.message === 'success') {
        return deleteInvoice.data;
      } else {
        const error = deleteInvoice.data;
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
}

export async function getSponsorList() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/new-ops/sponsors`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getSponsors = await axios(options);

    if (getSponsors) {
      if (getSponsors.data.message === 'success') {
        return getSponsors.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function submitPrescriptionsForDigitization(data) {
  const { reimbursementId, prescriptions } = data;
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/${reimbursementId}/submit-prescription-for-digitization`;
    const submitPrescription = await axios.post(url, prescriptions, {
      headers,
    });
    if (submitPrescription) {
      if (submitPrescription.data.message === 'success') {
        return submitPrescription.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getReimbursementProcedures(
  userId,
  dateOfInvoice,
  relativeId
) {
  const body = {
    userId,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    let url = `${config.baseUrl}/claims-support/reimbursements/get-procedures?userId=${userId}&invoiceDate=${dateOfInvoice}`;
    if (relativeId) {
      url = `${config.baseUrl}/claims-support/reimbursements/get-procedures?userId=${userId}&invoiceDate=${dateOfInvoice}&relativeId=${relativeId}`;
    }

    const options = {
      url,
      method: 'GET',
      headers,
      body,
    };
    const reimbursementProcedures = await axios(options);

    if (reimbursementProcedures) {
      if (reimbursementProcedures.data.message === 'success') {
        return reimbursementProcedures.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getWalletBalanceByReimbursementType(
  reimbursementId,
  procedureId,
  activeWalletDated,
  newPolicyId
) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${
      config.baseUrl
    }/claims-support/reimbursement/${reimbursementId}/wallet-balance/${procedureId}?activeWalletDated=${activeWalletDated}&newPolicyId=${
      newPolicyId ? newPolicyId : ''
    }`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const walletBalanceByReimbursementType = await axios(options);

    if (walletBalanceByReimbursementType) {
      if (walletBalanceByReimbursementType.data.message === 'success') {
        return walletBalanceByReimbursementType.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function updateClaim(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/v2/reimbursements?v=${version}`;
    const UpdateClaimData = await axios.post(url, body, { headers });
    if (UpdateClaimData) {
      if (UpdateClaimData.data.message === 'success') {
        return UpdateClaimData.data.result;
      } else {
        const error = UpdateClaimData.data;
        throw error;
      }
    }
  } catch (err) {
    throw err;
  }
}
export async function processClaimRequest(reimbursementId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursements-orders/claim/${reimbursementId}/request`;
    const options = {
      url,
      method: 'POST',
      headers,
    };
    const processClaimRequest = await axios(options);

    if (processClaimRequest) {
      if (processClaimRequest.data.message === 'success') {
        return processClaimRequest.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getUserByPhone(phone) {
  const body = {
    phone,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursements/user-by-phone?phone=${phone}`;
    const options = {
      url,
      method: 'GET',
      headers,
      body,
    };
    const getUserByPhone = await axios(options);

    if (getUserByPhone) {
      if (getUserByPhone.data.message === 'success') {
        return getUserByPhone.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getDependentsForUser(userId) {
  const body = {
    userId,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursements/get-dependents-for-user?userId=${userId}`;
    const options = {
      url,
      method: 'GET',
      headers,
      body,
    };
    const getDependentsForUser = await axios(options);

    if (getDependentsForUser) {
      if (getDependentsForUser.data.message === 'success') {
        return getDependentsForUser.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function addReimbursementRequest(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/add-reimbursement-for-user`;
    const addReimbursementRequest = await axios.post(url, body, { headers });
    if (addReimbursementRequest) {
      if (addReimbursementRequest.data.message === 'success') {
        return addReimbursementRequest.data;
      } else {
        const error = addReimbursementRequest.data;
        throw error;
      }
    }
  } catch (err) {
    throw err;
  }
}

export async function viewCommentRequest(reimbursementId) {
  const headers = {};
  const state = store.getState();
  const data = {};

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursements-orders/comments/${reimbursementId}/requests`;
    const viewCommentRequest = await axios.get(url, { headers });
    if (viewCommentRequest) {
      if (viewCommentRequest.data.message === 'success') {
        return viewCommentRequest.data.results;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    // throw error;
  }
}

export async function storeCommentRequest(reimbursementId, comment, type) {
  const data = {
    comment,
    type,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursements-orders/comments/${reimbursementId}/requests`;
    const options = {
      url,
      method: 'POST',
      headers,
      data,
    };
    const getDependentsForUser = await axios(options);

    if (getDependentsForUser) {
      if (getDependentsForUser.data.message === 'success') {
        return getDependentsForUser.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getReimbursementDeficiencyRemarks() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/reimbursements/deficiency-remarks`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getDeficiencyRemarks = await axios(options);

    if (getDeficiencyRemarks) {
      if (getDeficiencyRemarks.data.message === 'success') {
        return getDeficiencyRemarks.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function updateClaimToDocumentsDeficient(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/v2/reimbursements/documents-deficient`;
    const updateClaimToDocumentsDeficient = await axios.post(url, body, {
      headers,
    });
    if (updateClaimToDocumentsDeficient) {
      if (updateClaimToDocumentsDeficient.data.message === 'success') {
        return updateClaimToDocumentsDeficient.data.results;
      } else {
        throw new Error(
          updateClaimToDocumentsDeficient.data.errorMessage ||
            'Invalid response'
        );
      }
    }
  } catch (err) {
    const errorMessage =
      err.response?.data?.errorMessage || err.message || 'An error occurred';
    throw errorMessage;
  }
}

export async function getDoctorNameSuggestion(phoneText) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/doctor-name-suggestion?doctorName=${phoneText}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getdoctors = await axios(options);

    if (getdoctors) {
      if (getdoctors.data.message === 'success') {
        return getdoctors.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function verifyDoctorRegistration(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/verify-prescription-doctor`;
    const verifyDoctors = await axios.post(url, body, {
      headers,
    });
    if (verifyDoctors) {
      if (verifyDoctors.data.message === 'success') {
        return verifyDoctors.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function updateDoctorRegistration(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/verify-doctor-registration`;
    const result = await axios.post(url, body, {
      headers,
    });
    if (result) {
      if (result.data.message === 'success') {
        return result.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getReimbursementPrePostReq(claimId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/get/reimbursement/pre-post-request/${claimId}`;
    const result = await axios.get(url, {
      headers,
    });
    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function addClarificationReason(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-clarification`;
    const result = await axios.put(url, body, {
      headers,
    });
    if (result) {
      if (result.data.message === 'success') {
        return result.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function addClarificationSolution(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-clarification-solution`;
    const result = await axios.put(url, body, {
      headers,
    });
    if (result) {
      if (result.data.message === 'success') {
        return result.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function pastClaimData(
  reimbursementId,
  pastClaimStdate,
  pastClaimEdDate
) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/user-past-claims-details?reimbursementId=${reimbursementId}&claimSd=${pastClaimStdate}&claimEd=${pastClaimEdDate}`;
    const getPastClaims = await axios.get(url, { headers });

    if (getPastClaims) {
      return getPastClaims.data;
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getPolicyRemarks(sponsorId) {
  const body = {
    sponsorId,
  };
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/get-out-of-policy-sub-remarks?sponsorId=${sponsorId}`;
    const options = {
      url,
      method: 'GET',
      headers,
      body,
    };
    const policyRemarks = await axios(options);

    if (policyRemarks) {
      if (policyRemarks.data.message === 'success') {
        return policyRemarks.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getRemarkForDoc(params) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  const urlQuery = new URLSearchParams(params).toString();
  return await axios
    .get(`${config.baseUrl}/claims-support/get-Qc-Remarks?${urlQuery}`, {
      headers,
    })
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
    })
    .catch((err) => {
      console.log(err, '[getRemarkResponseError]');
      throw err;
    });
}

export async function addRemarkForDoc(
  opsUserId,
  remark,
  userId,
  reimbursementId,
  selectedReason
) {
  const headers = {};
  const state = store.getState();
  const body = { opsUserId, remark, userId, reimbursementId, selectedReason };

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-doc-remark`;
    const result = await axios.post(url, body, {
      headers,
    });
    if (result) {
      return result.data;
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function updateAcknowledgeBy(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/qc-remarks/update-acknowledge-by`;
    const result = await axios.post(url, body, {
      headers,
    });
    const data = result && result.data;
    if (data && data.message === 'success') {
      return result.data;
    } else {
      const err = data;
      throw err;
    }
  } catch (err) {
    throw err;
  }
}

export async function getPolicyFiles(sponsorId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/get-policy-files?sponsorId=${sponsorId}`;
    const result = await axios.get(url, {
      headers,
    });
    const data = result && result.data;
    if (data && data.message === 'success') {
      return data.data;
    } else {
      const err = data;
      throw err;
    }
  } catch (err) {
    throw err;
  }
}

export async function getPolicyDependents(
  reimbursementId,
  procedureId,
  sponsorId,
  invoiceDate
) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/get-all-policies-members?reimbursementId=${reimbursementId}&sponsorId=${sponsorId}&procedureId=${procedureId}&invoiceDate=${invoiceDate}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const policyMembers = await axios(options);

    if (policyMembers) {
      if (policyMembers.data.message === 'success') {
        return policyMembers.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function fetchCopyClaimsList(claimId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/get-all-copy-claims?claimId=${claimId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const copyClaimList = await axios(options);

    if (copyClaimList) {
      if (copyClaimList.data.message === 'success') {
        return copyClaimList.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export const downloadReimbursementCsvFile = (params) => {
  const urlQuery = new URLSearchParams(params).toString();
  const url = `${config.baseUrl}/claims-support/download-reimbursements-csv?${urlQuery}`;
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  window.open(`${url}&auth=${headers.authorization.split(' ')[1]}`);
  return Promise.resolve();
};

export async function getReimbursementInfo(
  page,
  pageSize,
  sponsor,
  searchString,
  reason,
  status,
  claimedBy,
  startDate,
  endDate,
  sortBy,
  sortOrder,
  remark
) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return await axios
    .get(
      `${config.baseUrl}/claims-support/get-reimbursement-info?page=${page}&pageSize=${pageSize}&sponsor=${sponsor}&searchString=${searchString}&reason=${reason}&status=${status}&claimedBy=${claimedBy}&sd=${startDate}&ed=${endDate}&sortBy=${sortBy}&sortOrder=${sortOrder}&remark=${remark}`,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function addPolicySubRemarks(subRemark, sponsorId) {
  const headers = {};
  const state = store.getState();
  const data = {
    subRemark,
    sponsorId,
  };
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/add-policy-sub-remarks`;
    const options = {
      url,
      method: 'POST',
      headers,
      data,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result;
      } else {
        const error = result;
        throw error;
      }
    }
  } catch (err) {
    throw err;
  }
}

export async function updateIsCheckedForDoctor(body) {
  const headers = {};
  const data = body;
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/is-checked/reimbursement-doctor`;
    const options = {
      url,
      method: 'POST',
      headers,
      data,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = result.data.errorMessage;
        throw error;
      }
    }
  } catch (err) {
    throw err;
  }
}

export async function getOpsIsChecked() {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/is-checked/reimbursement-doctor`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getDiseases(searchQuery) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return axios
    .get(`${config.baseUrl}/claims-support/get-disease-list`, {
      headers,
      params: {
        q: searchQuery,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}
export async function getDoctors(
  searchQuery,
  centreId,
  verticalId,
  nonEmpanelCenterId
) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  return axios
    .get(`${config.baseUrl}/claims-support/get-doctor`, {
      headers,
      params: {
        q: searchQuery,
        verticalId,
        centerId: centreId,
        nonEmpanelCenterId,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function getCenters(searchQuery, isReimbursement) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  return axios
    .get(`${config.baseUrl}/claims-support/get-center-doctor`, {
      headers,
      params: {
        q: searchQuery,
        isReimbursement,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function reimbursementMarkDigitized(digitisationData) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  const body = {
    digitisationData,
  };
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement/mark-digitized?v=${version}`;
    const result = await axios.post(url, body, {
      headers,
    });
    const data = result && result.data;
    if (data && data.message === 'success') {
      return result.data;
    } else {
      const err = data;
      throw err;
    }
  } catch (err) {
    throw err;
  }
}

export async function getNotifications(AdminUser) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/get-notifications`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function markNotificationRead(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/mark-notification-read`;
    const result = await axios.post(url, body, {
      headers,
    });
    const data = result && result.data;
    if (data && data.message === 'success') {
      return result.data;
    } else {
      const err = data;
      throw err;
    }
  } catch (err) {
    throw err;
  }
}
export async function markIsOnBreak(isOnBreak) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/mark-is-on-break/${isOnBreak}`;
    const options = {
      url,
      method: 'POST',
      headers,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getDailyReportsData(starDate, endDate) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-doctor-daily-report?startDate=${starDate}&endDate=${endDate}`;

    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getDailyReportsDataDownload(starDate, endDate) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-doctor-daily-report-download?startDate=${starDate}&endDate=${endDate}`;
    window.open(`${url}&auth=${headers.authorization.split(' ')[1]}`);
    return Promise.resolve();
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getAllDailyReportsData(reqDate, isDailyReport, endDate) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/reimbursement-all-doctor-daily-report?reqDate=${reqDate}&isDailyReport=${isDailyReport}&endDate=${endDate}`;

    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);

    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getBlacklistHospital(q, isDiagnostic) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/fetch-blacklisted-hospitals?q=${q}&isDiagnostic=${isDiagnostic}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);
    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function checkMerchantNameIsBlacklisted(q) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/check-merchant-name-is-blacklisted?q=${q}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const result = await axios(options);
    if (result) {
      if (result.data.message === 'success') {
        return result.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function markUserClaimSuspicious(body) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/mark-claim-suspicious`;
    const markSuspicious = await axios.post(url, body, { headers });
    const data = markSuspicious && markSuspicious.data;
    if (data.success) {
      return data.message;
    } else {
      const error = data.errorMessage;
      throw error;
    }
  } catch (err) {
    throw err;
  }
}

export async function downloadCsvBroker(params) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  const urlQuery = new URLSearchParams(params).toString();
  return await axios
    .get(
      `${
        config.baseUrl
      }/claims-support/reimbursements?sortOrder[]=${''}&sortBy[]=${''}&${urlQuery}`,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'recordsFound') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    });
}

export async function getVendorList() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/get-vendors`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getVendorList = await axios(options);

    if (getVendorList) {
      if (getVendorList?.data?.success) {
        return getVendorList.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getConsultations(userId, appointmentDate) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/fetch-consultations?userId=${userId}&appointmentDate=${appointmentDate}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };

    const getConsultations = await axios(options);
    if (getConsultations) {
      if (getConsultations?.data?.success) {
        return getConsultations.data.result;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
export async function getDocumentDeficientQueryCount(reimbursementId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/get/document-deficiency?reimbursementId=${reimbursementId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };

    const getDocumentDeficientQueryCount = await axios(options);
    if (getDocumentDeficientQueryCount) {
      if (getDocumentDeficientQueryCount?.data?.message === 'success') {
        return getDocumentDeficientQueryCount.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getDocumentDeficientQueryHistory(deficientId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/get/document-deficiency-history?deficientId=${deficientId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getDocumentDeficientQueryHistory = await axios(options);

    if (getDocumentDeficientQueryHistory) {
      if (getDocumentDeficientQueryHistory?.data?.message === 'success') {
        return getDocumentDeficientQueryHistory.data.formattedData;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getLabsPartner(q) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/labs-partners?q=${q}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getLabsPartner = await axios(options);

    if (getLabsPartner) {
      if (getLabsPartner?.data?.message === 'success') {
        return getLabsPartner.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function addNonEmpanelLabsPartner(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/add-non-empanel-labs-partner`;
    const result = await axios.post(url, body, { headers });

    if (result) {
      if (result.data.message === 'success') {
        return result.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function fetchAddressByPincode(pincode) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }
  try {
    const url = `${config.baseUrl}/claims-support/fetch-address-by-pincode?pincode=${pincode}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };

    const getAddressByPincode = await axios(options);

    if (getAddressByPincode) {
      if (getAddressByPincode?.data?.message === 'success') {
        return getAddressByPincode.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getVerticals() {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/fetch-verticals`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getVerticals = await axios(options);

    if (getVerticals) {
      if (getVerticals?.data?.message === 'success') {
        return getVerticals.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function fetchConsultationsReq(userId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support/fetch-consultation-requests?userId=${userId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getVerticals = await axios(options);

    if (getVerticals) {
      if (getVerticals?.data?.success) {
        return getVerticals.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function getLabTests(searchQuery) {
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  return axios
    .get(`${config.baseUrl}/labs/all`, {
      headers,
      params: {
        s: searchQuery,
        r: 1,
      },
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody.labs;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function getMedicines(searchQuery) {
  const sourceType = 'REIMBURSEMENT';
  const limit = 10;
  const headers = {};
  const state = store.getState();
  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  const url = `${config.pharmacyUrl}/drug/search?searchDrugSourceType=${sourceType}&limit=${limit}&drugName=${searchQuery}`;

  return axios
    .get(url, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.success) {
        return responseBody.drugs;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function fetchPreviousDigitized(userId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/common-digitisation/fetch-past-digitized-documents?userId=${userId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getRequests = await axios(options);

    if (getRequests) {
      if (getRequests?.data?.success) {
        return getRequests.data.result;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function fetchDigitisationDocumentsId(requestId) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/common-digitisation/fetch-reimbursed-documents/${requestId}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getCodiIds = await axios(options);

    if (getCodiIds) {
      if (getCodiIds?.data?.message === 'success') {
        return getCodiIds.data.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function fetchDigitisedItems(codiIds) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/common-digitisation/fetch-past-digitized-carts?codiIds=${codiIds}`;
    const options = {
      url,
      method: 'GET',
      headers,
    };
    const getItems = await axios(options);

    if (getItems) {
      if (getItems?.data?.message === 'success') {
        return getItems.data;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}

export async function changeDocumentTypeReq(body) {
  const headers = {};
  const state = store.getState();

  if (state.app.authToken) {
    headers.authorization = state.app.authToken;
  }

  try {
    const url = `${config.baseUrl}/claims-support//update/document-type`;
    const updateDoc = await axios.post(url, body, { headers });
    if (updateDoc) {
      if (updateDoc.data.success) {
        return updateDoc.data.success;
      } else {
        const error = new Error('Invalid response');
        throw error;
      }
    }
  } catch (err) {
    const error = new Error('Invalid response');
    throw error;
  }
}
